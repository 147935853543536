.dialog {
  box-sizing: border-box;
  width: 240px;
  border-radius: 4px;
  text-align: center;
  padding: 24px;
  z-index: 99999;
}

.dialog-icon{
  font-size: 350%
}
