.auth-panel {
  display: block;
  width: 320px;
  padding: 8px 0;
  text-align: center;
  margin: 0 auto;
  color: #092940;
}

.auth-panel-login-paper {
  margin-top: 16px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  padding: 8px 8px 12px;
}

.auth-panel-login-paper-button{
  margin-top: 8px;
}
